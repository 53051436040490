<template>
  <b-card
      class="salescost-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salescostForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="费用编号"
              label-for="cost_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="cost_no"
                size="sm"
                v-model="salescost.cost_no"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺名称"
              label-for="store_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="storeArray"
                v-model="store"
                class="select-size-sm"
                @input="changeSelect(['store_id','store_name'],$event)"
                :disabled="!isEmpty(storeId)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="终端客户"
              label-for="label"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="channelArray"
                v-model="channel"
                class="select-size-sm"
                @input="changeSelect(['channel_id','channel_name'],$event)"
                :disabled="!isEmpty(channelId)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="费用科目"
              label-for="type"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="getCodeOptions('sales_cost_type')"
                v-model="salescost.types"
                class="select-size-sm"
                @input="changeSelect(['type'],$event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="品牌名称"
              label-for="brand_name"
              label-size="sm"
              :class="'mb-1' + ((this.salescost.type==3||this.salescost.type==5||this.salescost.type==6||this.salescost.type==7||this.salescost.type==8||this.salescost.type==10||this.salescost.type==11)?' required':'')"
          >
            <v-select
                :options="getCodeOptions('brand')"
                v-model="salescost.brand"
                class="select-size-sm"
                @input="changeSelect(['brand_id','brand_name'],$event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="费用金额"
              label-for="amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="amount"
                size="sm"
                v-model="salescost.amount"
                type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="关联编号"
              label-for="amount"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="salescost.orderNo"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="salescost.remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="salescost.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="salescost.attachments"
                               :object_type="'sales_cost'"
                               :object_id="salescost.cost_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="submit"
              v-if="readonly == 0"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
// import salescostStore from '../salescostStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import storeStore from "@/views/apps/store/storeStore";
import {getUserData} from "@/auth/utils";
import storesalesStore from "@/views/apps/storesales/storesalesStore";

export default {
  components: {
    AttachmentUpload,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  props:{
    storeId:{
      type:Number,
      default: null
    },
    channelId:{
      type:Number,
      default: null
    },
  },
  data() {
    return {
      id: ref(0),
      salescost: {},
      store: {},
      channel: {},
      channelArray: [],
      storeArray: [],
      readonly: 0,
    }
  },
  setup(props) {
    const toast = useToast()

    // Register module
    // if (!store.hasModule('salescost')) store.registerModule('salescost', salescostStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule('salescost')) store.unregisterModule('salescost')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
    })

    const edit = function () {
      store.dispatch('salescost/edit', {id: this.id}).then(res => {

        this.salescost = res.data.data
        this.salescost.orderNo = res.data.data.ext.orderNo
        if (!isEmpty(props.storeId)&&!isEmpty(props.channelId)){
          this.salescost.store_id = props.storeId
          this.salescost.store_name = getCodeLabel('store',props.storeId)
          this.store = getCode('store',props.storeId)
          this.salescost.channel_id = props.channelId
          this.salescost.channel_name = getCodeLabel('company',props.channelId)
          this.channel = getCode('company',props.channelId)
          this.$forceUpdate()
        }
        this.salescost.types = getCode('sales_cost_type', this.salescost.type)
        this.salescost.brand = getCode('brand', this.salescost.brand_id)
        this.$forceUpdate()
      })
    }

    const view = function () {
      store.dispatch('salescost/view', {id: this.id}).then(res => {
        this.salescost = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      // const mydata = {}
      // mydata.st = +(new Date);
      // window.localStorage.setItem('sc-msg', JSON.stringify(this.salescost));
      this.readonly = 1
      if (isEmpty(this.salescost.store_id) || isEmpty(this.salescost.store_name)) {
        toast.error("店铺必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.channel_id) || isEmpty(this.salescost.channel_name)) {
        toast.error("终端客户必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.type)) {
        toast.error("费用科目必选！")
        this.readonly = 0
        return
      }
      if ((this.salescost.type == 3 || this.salescost.type == 5 || this.salescost.type == 6 || this.salescost.type == 7||this.salescost.type==8||this.salescost.type==10||this.salescost.type==11) && (isEmpty(this.salescost.brand_id) || isEmpty(this.salescost.brand_name))) {
        toast.error("品牌必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.amount)) {
        toast.error("费用金额必填！")
        this.readonly = 0
        return
      }
      store.dispatch('salescost/save', this.salescost).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-salescost-list'});
        } else {
          toast.error(res.data.data)
        }
        this.readonly = 0
      })
    }

    const submit = function () {
      this.readonly = 1
      if (isEmpty(this.salescost.store_id) || isEmpty(this.salescost.store_name)) {
        toast.error("店铺必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.channel_id) || isEmpty(this.salescost.channel_name)) {
        toast.error("终端客户必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.type)) {
        toast.error("费用科目必选！")
        this.readonly = 0
        return
      }
      if ((this.salescost.type == 3 || this.salescost.type == 5 || this.salescost.type == 6 || this.salescost.type == 7||this.salescost.type==8||this.salescost.type==10||this.salescost.type==11) && (isEmpty(this.salescost.brand_id) || isEmpty(this.salescost.brand_name))) {
        toast.error("品牌必选！")
        this.readonly = 0
        return
      }
      if (isEmpty(this.salescost.amount)) {
        toast.error("费用金额必填！")
        this.readonly = 0
        return
      }
      store.dispatch('salescost/save', this.salescost).then(response => {
        toast.success('数据已保存!')
        store.dispatch('salescost/changeStatus', {id: response.data.data.cost_id, status: 2}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功,单据已自动添加至费用列表！")
            const data = res.data.data
            data.ext = {}
            this.$emit('cost',data)
            this.edit()
            this.salescost.loaded = false
          } else {
            toast.error(res.data.data)
          }
          this.readonly = 0
        })
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.salescost[id] = result
    }

    const changeSelect = function (key, event) {
      this.salescost[key[0]] = isEmpty(event) ? null : event.value
      if (!isEmpty(key[1])) {
        this.salescost[key[1]] = isEmpty(event) ? null : event.label
      }
      if (key[0] == 'store_id') {
        this.channel = null
        this.salescost.channel_id = null
        this.salescost.channel_name = null
      }
      this.$forceUpdate()
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      changeSelect,
      submit,
      isEmpty,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
  watch: {
    store: {
      handler(newValue, oldValue) {
        this.channelArray = []
        store.dispatch('company/search', {
          status: 4,
          state: 1,
          type: 6,
          store_id: isEmpty(this.salescost.store_id) ? 0 : this.salescost.store_id
        })
            .then(response => {
              let data = response.data.data.list
              this.channelArray = []
              for (let i = 0; i < data.length; i++) {
                this.channelArray.push({"label": data[i].company_name, "value": data[i].company_id})
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
