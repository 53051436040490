<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong v-for="(price,index) in calPrice" :key="index">
                    {{
                      getCodeLabel('statement_order_type', price.statement_type) + '--对账金额：' + price.receivable_total + '，应收余额：' + price.remain_total
                    }}
                    <br>
                  </strong></span>
                </div>
              </b-alert>
            </div>
            <!--            <modal-list-->
            <!--                    :type="this.$route.query.type"-->
            <!--                    @refresh="refresh()"-->
            <!--                    :isEdit="!isEmpty(statement.statement_id)"-->
            <!--                    :statement="statement"-->
            <!--                    :readonly="readonly"-->
            <!--            >-->
            <!--            :to="{name:'apps-datacentersales-view-modalList',query:{type:this.$route.query.type,isEdit:!isEmpty(statement.statement_id),readonly:true,statement:statement}}"-->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                :to="{name:$route.query.type==1?'apps-datacentersales-view-modalList':'apps-datacentersales-view-offModalList',query:{type:this.$route.query.type,id:statement.statement_id,readonly:true}}"
                class="ml-2"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">新增</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="collection"
                  class="ml-1"
                  v-if="this.$route.query.type==1||this.$route.query.type==2"
              >
                <feather-icon
                    icon="DollarSignIcon"
                    class="mr-50"
                />
                <span class="align-right">收款</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search_excel"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">导入</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="printExcel"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">打印</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="batchChangeStatus(1,'批量提交审核')"
                  class="ml-1"
              >
                <feather-icon
                    icon="CircleIcon"
                    class="mr-50"
                />
                <span class="align-right">批量提交审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="batchChangeStatus(2,'批量审核通过')"
                  class="ml-1"
              >
                <feather-icon
                    icon="CircleIcon"
                    class="mr-50"
                />
                <span class="align-right">批量审核通过</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对账编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.statementNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--              <b-col-->
              <!--                  cols="12"-->
              <!--                  md="4"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="关联编号:"-->
              <!--                    label-for="relational_no"-->
              <!--                    label-size="sm"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      v-model="condition.relationalNo"-->
              <!--                      name="relational_no"-->
              <!--                      class="d-inline-block mr-1"-->
              <!--                      size="sm"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联编号:"
                    label-for="relational_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.relationalNo"
                      name="relational_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="明细关联编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.orderNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款结清"
                    label-for="qtyIsZero"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('yesno')"
                      v-model="condition.remainTotalIsZero"
                      name="qtyIsZero"
                      class="select-size-sm"
                      placeholder="请选择收款否为结清"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对账类型"
                    label-for="statement_type"
                    label-size="sm"
                >
                  <v-select
                      id="statement_type"
                      :options="getCodeOptions('statement_order_type')"
                      v-model="condition.statementOrderType"
                      name="statement_type"
                      class="select-size-sm"
                      placeholder="请选择对账类型"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单状态"
                    label-for="statement_type"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('statement_status')"
                      v-model="condition.status"
                      name="statement_type"
                      class="select-size-sm"
                      placeholder="请选择订单状态"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="condition.addTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="对账日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['statementTime'])"
                                 :params="['statementTime']"
                                 :value="condition.statementTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="预计到账"
                                 type="time"
                                 v-on:change="fromChildren($event,['statementOverDay'])"
                                 :params="['statementOverDay']"
                                 :value="condition.statementOverDay"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="condition.channelName"
                    modalName="终端客户和渠道"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="销售员"
                    type="input"
                    v-on:change="fromChildren($event,['salesName','salesId'])"
                    :params="['salesName','salesId']"
                    :value="condition.salesName"
                    modalName="销售员"
                    placeholder="点击选择销售员"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creator','creatorId'])"
                    :params="['creator','creatorId']"
                    :value="condition.creator"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <xy-input-button-->
<!--                    label="店铺"-->
<!--                    type="input"-->
<!--                    v-on:change="fromChildren($event,['storeName','storeId'])"-->
<!--                    :params="['storeName','storeId']"-->
<!--                    :value="condition.storeName"-->
<!--                    modalName="店铺"-->
<!--                    placeholder="点击选择店铺"-->
<!--                >-->
<!--                </xy-input-button>-->
<!--              </b-col>-->

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.storeArrays"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="storeArray"
                      class="select-size-sm"
                      placeholder="请选择店铺"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>


              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="销售日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['salesTime'])"
                                 :params="['salesTime']"
                                 :value="condition.salesTime"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="reload"
                >
                  <span class="align-middle">清空勾选</span>
                </b-button>

<!--                <b-button-->
<!--                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                    variant="outline-primary"-->
<!--                    @click="Export"-->
<!--                    v-if="$route.query.type==1"-->
<!--                >-->
<!--                  <span class="align-right">导出</span>-->
<!--                </b-button>-->

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="offExport"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          :tbody-tr-class="rowClass"
          v-model="table"
      >

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">
                <template #cell(sales_time)="data">
                  {{ toDate(data.item.sales_time) }}
                </template>

                <template #cell(creator)="data">
                  {{ getCodeLabel("user", data.item.ext.creator) }}
                </template>

                <template #cell(is_check)="data">
                  <feather-icon
                      icon="XIcon"
                      size="21"
                      v-if="data.item.is_check==0"
                  />
                  <feather-icon
                      icon="CheckIcon"
                      size="21"
                      v-else
                  />
                </template>

                <template #cell(order_no)="data">
                  <b-link
                      :to="{ name: (data.item.order_no.includes('SO')?((data.item.order_no.includes('OFSO')||data.item.order_no.includes('AFSO'))?'apps-offlinesalesorder-edit':(data.item.order_no.includes('QZSO')?'apps-presalesorder-list':'apps-salesorder-edit')):'apps-salesreturnbound-edit'), query: { id: data.item.order_id,orderNo:(data.item.order_no.includes('OFSO')||data.item.order_no.includes('AFSO'))?null:data.item.order_no },params:{readonly:1} }"
                      target="_blank"
                  >
                    {{ data.item.order_no }}
                  </b-link>

                </template>

                <template #cell(actions)="data">
                  <b-dropdown
                      variant="link"
                      no-caret
                  >
                    <template #button-content>
                      <!--多菜单-->
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>
                    <div>
                      <b-dropdown-item @click="computeStatementSubTotal(data.item)">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">已对账金额自动纠正</span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <template #cell(statement_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.statement_no }}
        </template>

        <template #cell(type)="data">
          {{ data.item.type == 1 ? '线上' : '线下' }}
        </template>

        <template #cell(order_total)="data">
          {{ (data.item.receivable_total + (isEmpty(data.item.ext.cost)?0:data.item.ext.cost)).toFixed(2) }}
        </template>

        <template #cell(cost)="data">
          {{ isEmpty(data.item.ext.cost)?'':0-data.item.ext.cost }}
        </template>

        <!-- Columns -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-link-->
        <!--              :to="{ name: 'apps-statement-edit', query: { id: data.item.id } }"-->
        <!--              class="font-weight-bold d-block text-nowrap"-->
        <!--          >-->
        <!--            #{{ data.item.id }}-->
        <!--          </b-link>-->
        <!--        </template>-->

        <!-- Column: Type -->
        <template #cell(statement_over_day)="data">
          {{ toDate(data.item.statement_over_day) }}
        </template>


        <template #cell(after_adjust_price)="data">
          {{ addNumber(data.item.receivable_total, data.item.change_total) }}
        </template>

        <template #cell(change_total)="data">
          <b-link v-if="isNumber(data.item.change_total)!=0"
                  :to="{ name: 'apps-statementchange-list', query: { id: data.item.statement_id } }"
                  target="_blank"
          >
            {{ data.item.change_total }}
          </b-link>

        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(other_cost)="data">
          {{ data.item.other_cost }}
          <feather-icon icon="EditIcon" @click="showOtherCost(data.item)"/>
        </template>

        <template #cell(day)="data">
          {{ data.item.ext.day <= 0 ? '' : data.item.ext.day }}
        </template>

        <template #cell(received_tatal)="data">
          {{ data.item.received_tatal }}{{ data.item.ext.approvalAmount }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(remark)="data">
          {{ isEmpty(data.item.remark) ? '' : data.item.remark.substring(0, 10) }}
          <div :id="`remark-row-${data.item.statement_id}`">
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.statement_id}`"
              placement="top"
          >
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <template #cell(statement_type)="data">
          {{ getCodeLabel('statement_order_type', data.item.statement_type) }}
        </template>

        <template #cell(statement_day)="data">
          {{ toDate(data.item.statement_over_day-(data.item.statement_over*86400)) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('statement_status', data.item.status)}`"
          >
            {{ getCodeLabel("statement_status", data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <!--            <b-dropdown-item @click="edit(data.item,false)">-->
            <!--              <feather-icon icon="FileTextIcon"/>-->
            <!--              <span class="align-middle ml-50">查看</span>-->
            <!--            </b-dropdown-item>-->

            <!--            <b-dropdown-item @click="edit(data.item,true)" v-if="data.item.status===0">-->
            <!--              <feather-icon icon="FileTextIcon"/>-->
            <!--              <span class="align-middle ml-50">编辑</span>-->
            <!--            </b-dropdown-item>-->
            <b-dropdown-item
                :to="{name:$route.query.type==1?'apps-datacentersales-view-modalList':'apps-datacentersales-view-offModalList',query:{type:$route.query.type,id:data.item.statement_id,readonly:false}}">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>

            <b-dropdown-item
                :to="{name:$route.query.type==1?'apps-datacentersales-view-modalList':'apps-datacentersales-view-offModalList',query:{type:$route.query.type,id:data.item.statement_id,readonly:true}}"
                v-if="data.item.status===0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status===0">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'审核通过')" v-if="data.item.status===1">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,0,'驳回')" v-if="data.item.status===1">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'撤销审核')" v-if="data.item.status===2">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">撤销审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="del(data.item)" v-if="data.item.status===0">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- 其他费用弹出窗口 -->
    <b-modal
        id="otherCostModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="其他费用列表"
        ref="otherCostModal"

    >
      <statement-cost-list
          ref="otherCostList" :statementId="statementId" v-on:table="toRefetch">
      </statement-cost-list>
    </b-modal>

    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="saveRemark"
        cancel-title="取消"
        centered
        size="lg"
        title="修改备注"
        ref="remarkModal"
    >
      <b-card :header="`编号：${selectedItem.statement_no}`">
        <b-form-textarea
            id="textarea"
            v-model="remarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>

    <b-modal
        id="modal-add"
        ok-only
        ok-title="取消"
        centered
        size="xl"
        title="信息"
        ref="addModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <modal-list
          :type="this.$route.query.type"
          @refresh="refresh()"
          :isEdit="!isEmpty(statement.statement_id)"
          :statement="statement"
          :readonly="readonly"
      >

      </modal-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isNumber, isEmpty
} from '@core/utils/filter'
import statementUseList from './statementUseList'
import statementStore from './statementStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import {useToast} from "vue-toastification/composition";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ModalList from "@/views/apps/datacentersales/modal/ModalList";
import storeStore from "@/views/apps/store/storeStore";

export default {
  components: {
    ModalList,
    StatementCostList,
    AttachmentUpload,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return
      }
      if (item.remain_total == -999999) {
        return
      }
      if (item.ext.day >= -3 && item.ext.day <= 0) {
        return 'table-warning'
      }
      if (item.ext.day > 0) {
        return 'table-danger'
      }
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statement/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.remark
      this.$refs['remarkModal'].show()
    },
    showAdd() {
      this.statement = {}
      this.readonly = true
      this.$refs['addModal'].show()
    },
    searchByCondition() {
      this.reload();
    },
    resetCondition() {
      this.condition = {}
      this.reload()
    },
    // 点击全选按钮
    toggleAll(checked) {
      const checkIndexArray = []
      const tableIdArray = []
      this.table.forEach((value) => {
        tableIdArray.push(value.entity_id)
      })
      this.checked.forEach((value, index) => {
        if (tableIdArray.indexOf(value.entity_id) > -1) {
          checkIndexArray.push(index)
        }
      })
      for (let i = checkIndexArray.length - 1; i >= 0; i--) {
        this.checked.splice(checkIndexArray[i], 1);
      }
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = -1;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].entity_id == id.entity_id) {
          index = i
        }
      }
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
  },
  data() {
    return {
      fields: [
        {key: 'item_id', label: '序号'},
        {key: 'is_check', label: '标记'},
        {key: 'order_no', label: '编号'},
        {key: 'creator', label: '制单人'},
        {key: 'sales_name', label: '业务员'},
        {key: 'total_qty', label: '销售数量'},
        {key: 'total_amount', label: '销售金额'},
        {key: 'sales_time', label: '销售日期'},
        {key: 'actions', label: '操作'},
      ],
      advanced_search_modal: false,
      statementId: 0,
      selectedItem: {},
      remarkInfo: null,

      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      advanced_search_modal_excel: false,
      show: true,
      excelSqlId: '',
      type: '',
      statement: {},
      readonly: true,
      storeArray:[],

    }
  },
  mounted() {
    this.isCheckedObj = {};
    this.table.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.entity_id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(value.entity_id) > -1) {
            show += 1;
          }
        })
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (this.table.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.entity_id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(value.entity_id) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
      }
    },
    $route(to, from) {
      this.type = this.$route.query.type
      this.routeType = this.$route.query.type
      this.reload()
    },
  },
  setup() {
    const toast = useToast()
    const router = useRouter()

    // Register module
    if (!store.hasModule('statement')) store.registerModule('statement', statementStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statement')) store.unregisterModule('statement')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const saveRemark = function () {
      this.selectedItem.remark = this.remarkInfo
      store.dispatch('statement/saveRemark', {
        id: this.selectedItem.statement_id,
        remark: this.selectedItem.remark
      }).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const showOtherCost = function (params) {
      this.statementId = params.statement_id
      this.$refs['otherCostModal'].show()
    }

    const toRefetch = function (val) {
      refetchData()
    }

    const collection = function () {
      if (this.checked.length === 0) {
        toast.error("请选择对账单")
        return false
      }
      const set = new Set()
      const setWarehouse = new Set()
      const setType = new Set()
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].statement_id)
        set.add(this.checked[i].channel_id)
        setWarehouse.add(this.checked[i].warehouse_id)
        setType.add(this.checked[i].statement_type)
        if (this.checked[i].remain_total == 0) {
          toast.error("请选择应收余额不等于0的对账单!")
          return false
        }
      }
      if (set.size != 1) {
        toast.error("请选择相同终端客户的对账单!")
        return false
      }

      // if (this.checked[0].type == 2 && setWarehouse.size != 1) {
      //   toast.error("请选择相同仓库的对账单!")
      //   return false
      // }
      //合并收款
      // if (setType.size != 1) {
      //   toast.error("请选择相同对账类型的对账单!")
      //   return false
      // }
      const ids = idArray.join(",")
      store.dispatch('statement/createStatementReceive', {ids: ids}).then(res => {
        if (res.data.code === 0) {
          toast.success("创建成功")
          this.reload()
          let routeData = this.$router.resolve({
            name: router.route.value.query.type == 1 ? 'apps-statementreceive-ol-edit' : 'apps-statementreceive-edit',
            query: {id: res.data.data.receive_id, type: router.route.value.query.type, readonlys: 0},
          })
          window.open(routeData.href, '_blank')
          // this.$router.push({name: 'apps-statementreceive-edit', query: { id: res.data.data.receive_id,type:router.route.value.query.type },params:{readonly:0}});
        } else {
          toast.error(res.data.data)
          this.reload()
        }
      })
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const Export = function () {
      const storeArrays = []
      const storeArray = isEmpty(condition.value.storeArrays)?[]:condition.value.storeArrays
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      axios({
            url: '/api/exportexcelfile/createStatementExcel',
            method: 'get',
            params: {
              statementNo: condition.value.statementNo,
              orderNo: condition.value.orderNo,
              relationalNo: condition.value.relationalNo,
              remainTotalIsZero: condition.value.remainTotalIsZero == null ? null : condition.value.remainTotalIsZero.value,
              statementOrderType: condition.value.statementOrderType == null ? null : condition.value.statementOrderType.value,
              statementStatus:condition.value.status==null?null:condition.value.status.value,
              addTime: condition.value.addTime,
              statementOverDay: condition.value.statementOverDay,
              channelId: condition.value.channelId,
              warehouseId: condition.value.warehouseId,
              storeIds:storeArrays.join(','),
              salesId: condition.value.salesId,
              statementId: router.route.value.query.id === null ? '' : router.route.value.query.id,
              type: router.route.value.query.type,
              salesTime: condition.value.salesTime,
              creator: condition.value.creatorId,
              statementTime:condition.value.statementTime,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "对账单-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const offExport = function () {
      const storeArrays = []
      const storeArray = isEmpty(condition.value.storeArrays)?[]:condition.value.storeArrays
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      axios({
            url: '/api/exportexcelfile/statementOffExport',
            method: 'get',
            params: {
              statementNo: condition.value.statementNo,
              orderNo: condition.value.orderNo,
              relationalNo: condition.value.relationalNo,
              remainTotalIsZero: condition.value.remainTotalIsZero == null ? null : condition.value.remainTotalIsZero.value,
              statementOrderType: condition.value.statementOrderType == null ? null : condition.value.statementOrderType.value,
              statementStatus:condition.value.status==null?null:condition.value.status.value,
              addTime: condition.value.addTime,
              statementOverDay: condition.value.statementOverDay,
              channelId: condition.value.channelId,
              warehouseId: condition.value.warehouseId,
              storeIds:storeArrays.join(','),
              salesId: condition.value.salesId,
              statementId: router.route.value.query.id === null ? '' : router.route.value.query.id,
              type: router.route.value.query.type,
              salesTime: condition.value.salesTime,
              creator: condition.value.creatorId,
              statementTime:condition.value.statementTime,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "对账单-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const searchByConditionExcel = function () {
      this.show = false;
      const params = {
        excelSqlId: this.excelSqlId,
      }
      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      store.dispatch('statement/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('statement/changeStatus', {id: params.statement_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const del = function (params) {
      if (confirm("是否删除")) {
        store.dispatch('statement/del', {id: params.statement_id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const refresh = function () {
      this.$refs['addModal'].hide()
      setTimeout(() => {
        refetchData()
      }, 1000)
    }

    const edit = function (params, flag) {
      this.statement = params
      if (flag) {
        this.readonly = true
      } else {
        this.readonly = false
      }
      this.$refs['addModal'].show()
    }

    const printExcel = function () {
      if (this.checked.length === 0) {
        toast.error("请选择对账单")
        return false
      }
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].statement_id)
      }
      axios({
            url: '/api/exportexcelfile/printStatement',
            method: 'get',
            params: {
                ids:idArray.join(",")
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "对账单-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const batchChangeStatus = function (params,information) {
      if (this.checked.length == 0){
        toast.error("请选择单据!")
        return
      }
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].statement_id)
      }

      if (confirm("是否" + information)) {
        store.dispatch('statement/batchChangeStatus', {ids: idArray.join(","), status: params}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            this.reload()
          } else {
            toast.error(res.data.data)
            this.reload()
          }
        })
      }
    }

    const computeStatementSubTotal = function (params) {
      if (confirm("是否纠正已对账金额？")) {
        store.dispatch('statement/computeStatementSubTotal', {itemId:params.item_id}).then(res => {
          if (res.data.code == 0) {
            toast.success(res.data.data)
            this.reload()
          } else {
            toast.error(res.data.data)
            this.reload()
          }
        })
      }
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      calPrice,
      routeType,

      // UI
    } = statementUseList(
        {
          id: router.route.value.query.id,
          type: router.route.value.query.type
        }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      routeType,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,
      condition,
      calPrice,

      advanced_search,
      saveRemark,
      fromChildren,
      showOtherCost,
      toRefetch,
      collection,
      reload,
      addNumber,
      isNumber,
      Export,
      offExport,
      advanced_search_excel,
      onUploaded,
      searchByConditionExcel,
      changeStatus,
      del,
      refresh,
      edit,
      printExcel,
      batchChangeStatus,
      computeStatementSubTotal,
    }
  },
  created() {
    // console.log(useRouter())
    // this.type = useRouter().route.value.query.type
    this.type = this.$route.query.type || '';
    store.dispatch('store/search', {all:1})
        .then(response => {
          let data = response.data.data.list
          for (let i = 0; i < data.length; i++) {
            this.storeArray.push({'key': i, "label": data[i].store_name, "value": data[i].store_id})
          }
        })
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
