<template>
  <div>
    <b-form-checkbox
        v-if="type==='head'"
        v-model="allCheckedChild"
        @change="toggleAll"
        :indeterminate="indeterminateChild"
        :class="color"
    >
    </b-form-checkbox>

    <b-form-checkbox
        v-if="type==='row'"
        @change="checkboxClick(row.item)"
        v-model="rowCheckedChild"
        :class="color"
    >
    </b-form-checkbox>

  </div>

</template>

<script>

import XyGroup from './XyGroup'
import {VBModal} from 'bootstrap-vue'
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    XyGroup,
    VBModal,
    vSelect,
  },
  data(){
    return{
      allCheckedChild:false,
      isCheckedObjChild:[],
      indeterminateChild:false,
      rowCheckedChild:false,
    }
  },
  props: {
    //选中的行
    checked:{
      type: Array,
      default: []
    },
    //当前页面数据
    table:{
      type: Array,
      default: []
    },
    //类型:head:表头全选框,row:行级选择框
    type: {
      type: String,
      default: 'row'
    },
    //全选是否选中
    allChecked: {
      type:Boolean,
      default:false,
    },
    //表头-状态
    indeterminate: {
      type:Boolean,
      default:false,
    },
    //行数组
    isCheckedObj:{
      default:[]
    },
    //行数据
    row:{
      default:{}
    },
    //行状态
    rowChecked:{
      type:Boolean,
      default:false,
    },
    //颜色:'custom-control-'+...
    color:{
      type:String,
      default:'custom-control-primary',
    },
  },
  methods:{
    // 点击全选按钮
    toggleAll(checked) {
      const checkIndexArray = []
      const tableIdArray = []
      this.table.forEach((value)=>{
        tableIdArray.push(value.entity_id)
      })
      this.checked.forEach((value,index)=>{
        if (tableIdArray.indexOf(value.entity_id)>-1){
          checkIndexArray.push(index)
        }
      })
      for (let i = checkIndexArray.length-1; i >= 0; i--) {
        this.checked.splice(checkIndexArray[i], 1);
      }
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObjChild['index' + element.id] = checked;
      });
      this.$emit('update:isCheckedObj',this.isCheckedObjChild)
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = -1;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].entity_id == id.entity_id){
          index = i
        }
      }
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
      this.$emit('update:rowChecked',this.rowCheckedChild)
    },
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.entity_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(value.entity_id)>-1){
            show+=1;
          }
        })
        if (newVal.length == 0) {
          this.indeterminateChild = false
          this.allCheckedChild = false
        } else if (this.table.length === show) {
          this.indeterminateChild = false
          this.allCheckedChild = true
        } else if (show === 0){
          this.indeterminateChild = false
          this.allCheckedChild = false
        } else {
          this.indeterminateChild = true
          this.allCheckedChild = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table:{
      handler(newVal, oldVal){
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.entity_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(value.entity_id)>-1){
            show+=1;
          }
        })
        if (this.table.length === show) {
          this.indeterminateChild = false
          this.allCheckedChild = true
        }else if (show === 0){
          this.indeterminateChild = false
          this.allCheckedChild = false
        }else {
          this.indeterminateChild = true
          this.allCheckedChild = false
        }
      }
    },
    allCheckedChild:{
      handler(){
        this.$emit('update:allChecked',this.allCheckedChild)
      }
    },
    rowChecked:{
      handler(newVal, oldVal){
        this.rowCheckedChild = newVal
      }
    },
  },
  created() {
    this.allCheckedChild = this.allChecked
    this.isCheckedObjChild = this.isCheckedObj
    this.indeterminateChild = this.indeterminate
    this.rowCheckedChild = this.rowChecked
  }
}
</script>

<style scoped>

</style>
