<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <b-link
                target="_blank"
            >
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="ml-2"
                  @click="showCost"
              >

                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">创建费用</span>
              </b-button>
            </b-link>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用编号"
                    label-for="cost_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="wh_name"
                      size="sm"
                      v-model="condition.costNo"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用科目"
                    label-for="purchase_team_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('sales_cost_type')"
                      v-model="condition.statementCostType"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="purchase_team_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('brand')"
                      v-model="condition.brandName"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowEditSelected"
          v-model="editTable"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ getCodeLabel('sales_cost_type', data.item.type) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(pre_order_no)="data">
          {{ data.item.ext.psoOrderNo }} / {{ data.item.ext.soOrderNo }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('sales_cost_status', data.item.status)}`"
          >
            {{ getCodeLabel("sales_cost_status", data.item.status) }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-salesCost"
        ok-title="关闭"
        ok-only
        centered
        size="xl"
        title="增加"
        ref="salesCostModal"
    >
      <sales-cost-select-edit ref="salesCostSelectEdit"
                              :storeId="storeId"
                              :channelId="channelId"
                              v-on:cost="addCost"
      >
      </sales-cost-select-edit>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty} from '@core/utils/filter'
import salescostUseList from './salescostSelectUseList'
import salescostStore from '../salescostStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import XyTab from "@/views/components/xy/XyTab";
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import SalesCostSelectEdit from "@/views/apps/salescost/modal/SalesCostSelectEdit";

export default {
  components: {
    SalesCostSelectEdit,
    XyTab,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salescost/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    searchByCondition() {
      this.refetchData();
    },
    onRowEditSelected(items) {
      this.selected = items
    },
    getSelect(){
      return this.selected
    }
  },
  data() {
    return {
      advanced_search_modal: false,
      selected:[],
      editTable:[],
    }
  },
  directives: {
    Ripple,
  },
  props:{
    item:{
      type:Array,
      default:[]
    },
    storeId:{
      type:Number,
      default: null
    },
    channelId:{
      type:Number,
      default: null
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salescost')) store.registerModule('salescost', salescostStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salescost')) store.unregisterModule('salescost')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const showCost = function() {
      if (isEmpty(props.storeId)){
        toast.error("请选择店铺！")
        return
      }
      if (isEmpty(props.channelId)){
        toast.error("请选择终端客户！")
        return
      }
      this.$refs['salesCostModal'].show()
    }

    const addCost = function (params) {
      this.$emit('cost',params)
    }

    const {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salescostUseList(
        {
          item:props.item,
          storeId:props.storeId,
          channelId:props.channelId
        }
    )

    return {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      showCost,
      addCost,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
