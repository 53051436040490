<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
<!--            <label>显示</label>-->
<!--            <v-select-->
<!--                v-model="limit"-->
<!--                :options="limitOptions"-->
<!--                :clearable="false"-->
<!--                class="per-page-selector d-inline-block mx-50"-->
<!--            />-->
<!--            <label>条</label>-->
          </b-col>

          <b-col md="4"></b-col>

          <b-button
              variant="primary"
              @click="create"
              v-if="readonly===0"
          >
            <span class="text-nowrap">添加</span>
          </b-button>

          <!-- Search -->
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-form-input-->
          <!--                v-model="searchKey"-->
          <!--                class="d-inline-block mr-1"-->
          <!--                placeholder="搜索关键字..."-->
          <!--              />-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                :to="{ name: 'apps-statementcost-edit'}"-->
          <!--              >-->
          <!--                <span class="text-nowrap">添加</span>-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="statementCostList"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="1" variant="primary">
              <span><strong>费用小计</strong></span>
            </b-td>
            <b-td :colspan="2" variant="primary">
              <span><strong>{{allAmount}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <div style="width: 200px">
            <v-select
                id="type"
                :options="type==2?(getCodeOptions('statement_cost_type').filter(e=>e.value==2||e.value==6||e.value==9))
                :getCodeOptions('statement_cost_type_online')"
                :clearable="true"
                v-model="data.item.typeName"
                @input="changeSelect(data.item,$event)"
                class="select-size-sm"
                placeholder="请选择科目"
            />
          </div>
        </template>



        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel("user",data.item.creator)}}
        </template>

        <template #cell(amount)="data">
          <b-form-input
              id="amount"
              size="sm"
              v-model="data.item.amount"
              type="number"
              style="width: 80px"
              @blur="save(data.item)"
              @change="changeAmount(data.item,$event)"
              :readonly="readonly!==0"
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!--单菜单-->
          <b-row>
            <b-link @click="save(data.item)">
              <feather-icon
                  icon="SaveIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </b-link>
            <b-link>
              <feather-icon @click="del(data.item)"
                            icon="XSquareIcon"
                            size="16"
                            class="align-middle text-body"
              />
            </b-link>
          </b-row>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
<!--          <b-col-->
<!--              cols="12"-->
<!--              sm="6"-->
<!--              class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--          >-->

<!--            <b-pagination-->
<!--                v-model="start"-->
<!--                :total-rows="listTotals"-->
<!--                :per-page="limit"-->
<!--                first-number-->
<!--                last-number-->
<!--                class="mb-0 mt-1 mt-sm-0"-->
<!--                prev-class="prev-item"-->
<!--                next-class="next-item"-->
<!--            >-->
<!--              <template #prev-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronLeftIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--              <template #next-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronRightIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--            </b-pagination>-->

<!--          </b-col>-->

        </b-row>
      </div>
    </b-card>
    <!-- 退货扣款列表 -->
    <b-modal
        id="salesRefundModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="其他费用列表"
        ref="salesRefundModal"
    >
      <sales-refund-select-list
          ref="salesRefundList" :itemId="itemId"  v-on:receiveItem="receiveItemMethod">
      </sales-refund-select-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import statementcostUseList from './statementcostUseList'
import statementcostStore from './statementcostStore'
import {useToast} from "vue-toastification/composition";
import SalesRefundSelectList from "@/views/apps/salesrefund/SalesRefundSelectList";
import statementreceiveitemStore from "@/views/apps/statementreceiveitem/statementreceiveitemStore";

export default {
  components: {
    SalesRefundSelectList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    statementId: {
      type: Number,
    },
    statementreceive: {},
    sumTotal:{
      type:Number
    },
    readonly: {
      type: Number,
      default: 0,
    },
    type: {
      default: 0,
    },
  },

  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statementcost/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    changeSelect : function (params, event) {
      params['type'] = event == null ? null : event.value
      params['typeName'] = event == null ? null : event.label
      /*if(event.value==6){ //如果是退货扣款
        this.changeModel = true
        this.itemId = params.id
      }*/
    }
  },
  data() {
    return {
      receiveItem:[], // 销售退款单 数据
      changeModel:false,
      statementCostList:[],
      itemId :"",
    }
  },
  watch :{
    changeModel :{
      handler(newVal,oldVal){
        if(newVal){
          this.$refs['salesRefundModal'].show()
        }
      },
      immediate: true,
      deep: true
    },
    allAmount:{
      handler(){
        this.$emit("table",this.allAmount)
      }
    },
    sumTotal:{
      handler(newValue,oldValue){

      }
    }
  },

  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statementcost')) store.registerModule('statementcost', statementcostStore)
    if (!store.hasModule('statementreceiveitem')) store.registerModule('statementreceiveitem', statementreceiveitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementcost')) store.unregisterModule('statementcost')
      if (store.hasModule('statementreceiveitem')) store.unregisterModule('statementreceiveitem')
    })

    const create = function () {
      store.dispatch('statementcost/create', {statementId: this.statementId}).then(res => {
        if (res.data.code==0){
          toast.success('添加成功')
         let list = res.data.data
          for (let i = 0; i < list.length; i++) {
            list[i].typeName = getCodeLabel("statement_cost_type",list[i].type)
          }
          // this.searchList=list
          refetchData()
        }else {
          toast.error('添加失败')
        }
      })
    }

    const toFloat = function (params) {
      return parseFloat(params.toFixed(2))
    }

    const save = function (params) {
      if(params.amount==undefined||params.amount==''){
        return false;
      }
      if(params.typeName==undefined||params.typeName==''){
        alert("请选择科目!")
        return false;
      }
      // console.log(1,toFloat(this.statementreceive.ext.remainTotal))
      // console.log(2,toFloat(this.sumTotal))
      // console.log(3,toFloat(this.sumTotal)<toFloat(this.statementreceive.ext.remainTotal))
      // if ((toFloat(this.statementreceive.ext.remainTotal)>=0&&toFloat(this.sumTotal)<0)||(toFloat(this.statementreceive.ext.remainTotal)>=0&&toFloat(this.sumTotal)>toFloat(this.statementreceive.ext.remainTotal))){
      //   toast.error("费用金额不能大于预收金额!")
      //   params.amount = 0
      // }
      // if ((toFloat(this.statementreceive.ext.remainTotal)<=0&&toFloat(this.sumTotal)>0)||(toFloat(this.statementreceive.ext.remainTotal)<=0&&toFloat(this.sumTotal)<toFloat(this.statementreceive.ext.remainTotal))){
      //   toast.error("费用金额不能大于预收金额!")
      //   params.amount = 0
      // }
      // if (this.sumTotal<0){
      //   toast.error("费用金额不能大于预收金额!")
      //   params.amount = 0
      // }
      store.dispatch('statementcost/save',params).then(res => {
        if (res.data.code==0){
          toast.success('保存成功')
          refetchData()
          store.dispatch('statementreceiveitem/changeReceiveTotal', {
            id: this.statementreceive.receive_id,
          }).then(res => {
            if (res.data.code == 0) {
              this.$emit("refetchData",res.data.data.receive_total)
            } else {
              toast.error(res.data.data)
            }
          })
        }else {
          toast.error('保存失败')
        }
      })
    }

    const del = function (params) {
      if (confirm("确认删除吗?")){
        store.dispatch('statementcost/del', {id:params.id,statementId:params.statement_id}).then(res => {
          if (res.data.code==0){
            toast.success('删除成功')
            let list = res.data.data
            for (let i = 0; i < list.length; i++) {
              list[i].typeName = getCodeLabel("statement_cost_type",list[i].type)
            }
            // this.searchList=list
            store.dispatch('statementreceiveitem/changeReceiveTotal', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.$emit("refetchData",res.data.data.receive_total)
              } else {
                toast.error(res.data.data)
              }
            })
            refetchData()
          }else {
            toast.error('删除失败')
          }
        })
      }
    }
    const receiveItemMethod = function (val){
      this.receiveItem = val
      if(this.receiveItem.length>0){
        if(this.receiveItem[0].statement_no==""||this.receiveItem[0].statement_no==null){
          const id = val.itemId
          for (let i = 0, len = this.statementCostList.length; i < len; i++) {
            if(this.statementCostList[i].id==id){
              this.statementCostList[i]['refund_id'] = this.receiveItem[0].refund_id
              this.statementCostList[i]['refund_no'] = this.receiveItem[0].refund_no
              this.statementCostList[i]['amount'] = this.receiveItem[0].refund_tatal
            }
          }
          this.changeModel = false
          this.searchList = this.statementCostList
          refetchData()
        }else{
          toast.error("该退款单已经关联过对账单,请重新选择！")
        }
      }
    }

    const changeAmount = function (data,params) {
      let price = 0
      for (let i = 0, len = this.statementCostList.length; i < len; i++) {
        if(this.statementCostList[i].id==data.id){
          this.statementCostList[i]['amount'] = params
        }

        if(this.statementCostList[i]['amount'] != undefined && this.statementCostList[i]['amount'] != ''){
          price+=parseFloat(this.statementCostList[i]['amount'])
        }
      }
      // console.log(this.allAmount)
      this.allAmount = price
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      allAmount,

      // UI
    } = statementcostUseList({
      statementId:props.statementId,
      readonly: props.readonly,
      type:props.type
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      create,
      save,
      del,
      receiveItemMethod,
      changeAmount,

      allAmount,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
