<template>
  <b-tabs pills nav-class="nav-pill-primary" class="ml-2">
    <b-tab title="全部" @click="$emit('emitTabs',null)"></b-tab>
    <b-tab
        v-for="item in tabs"
        :title="item.label"
        @click="$emit('emitTabs',item.value)"
        :active="item.value===statusValue.toString()"
    >
    </b-tab>
  </b-tabs>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
  },
  data() {
    return {

    }
  },
  props: {
    //状态数组
    tabs: {
      type: Array,
    },
    //默认的状态
    statusValue:{

    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
