import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function statementUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'statement_no', label: '对账编号', sortable: true },
    { key: 'type', label: '类型', sortable: true },
    { key: 'relational_no', label: '关联编号', sortable: true },
    { key: 'statement_type', label: '对账类型', sortable: true },
    // { key: 'channel_id', label: '终端客户ID', sortable: true },
    { key: 'channel_name', label: '终端客户', sortable: true },
    { key: 'store_name', label: '店铺', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'order_total', label: '单据金额', sortable: true },
    { key: 'cost', label: '费用金额' },
    { key: 'receivable_total', label: '对账金额', sortable: true },
    { key: 'received_tatal', label: '已收金额', sortable: true },
    { key: 'remain_total', label: '应收余额', sortable: true },
    // { key: 'change_total', label: '调整金额', sortable: true },
    // { key: 'after_adjust_price', label: '调整后对账金额' },
    { key: 'remark', label: '备注', sortable: true  },
    { key: 'statement_day', label: '对账日期'},
    { key: 'statement_over', label: '账期天数', sortable: true },
    { key: 'statement_over_day', label: '预计到账日期', sortable: true },
    { key: 'day', label: '账龄/天'},
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建日期', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('statement_id')
  const isSortDirDesc = ref(true)
  const calPrice = ref({})
  const routeType = ref(0)

  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey,routeType], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (routeType.value==0){
      routeType.value=config.type
    }
    const storeArrays = []
    const storeArray = isEmpty(condition.value.storeArrays)?[]:condition.value.storeArrays
    for (let i = 0; i < storeArray.length; i++) {
      storeArrays.push(storeArray[i].value)
    }
    store
      .dispatch('statement/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        statementNo:condition.value.statementNo,
        orderNo:condition.value.orderNo,
        relationalNo:condition.value.relationalNo,
        remainTotalIsZero:condition.value.remainTotalIsZero==null?null:condition.value.remainTotalIsZero.value,
        statementOrderType:condition.value.statementOrderType==null?null:condition.value.statementOrderType.value,
        statementStatus:condition.value.status==null?null:condition.value.status.value,
        addTime:condition.value.addTime,
        statementTime:condition.value.statementTime,
        statementOverDay:condition.value.statementOverDay,
        channelId:condition.value.channelId,
        warehouseId:condition.value.warehouseId,
        storeIds:storeArrays.join(','),
        salesId:condition.value.salesId,
        statementId : config.id===null?'':config.id,
        type:routeType.value,
        salesTime:condition.value.salesTime,
        creator:condition.value.creatorId
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        calPrice.value = data.ext.price

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    calPrice,

    refetchData,
    condition,
    routeType,

    // Extra Filters
  }
}
